import React, { useRef } from "react";
import Slider from "react-slick";
import IMAGES from "../../img/image";
import "./HeroSection.css";

const HeroSection = () => {
  const arrowRef = useRef();

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 100,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* Desktop and Tablet  */}
      <div className="container-fluid hero-container">
        <Slider ref={arrowRef} {...settings}>
          <div className="p-3">
            <img src={IMAGES?.banner1} className="d-block w-100" alt="..." />
          </div>

          <div className="p-3">
            <img src={IMAGES?.banner2} className="d-block w-100" alt="..." />
          </div>

          <div className="p-3">
            <img src={IMAGES?.banner3} className="d-block w-100" alt="..." />
          </div>

          <div className="p-3">
            <img src={IMAGES?.banner4} className="d-block w-100" alt="..." />
          </div>
        </Slider>
      </div>
    </>
  );
};

export default HeroSection;
